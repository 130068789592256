import { graphql, Link } from "gatsby"
import * as React from "react"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { MapContainer, useMap, Marker, GeoJSON } from "react-leaflet"
import { getIcon, countryStyles } from "../components/utils/MapUtils"
import { MemberInfo } from "../components/utils/Model"
import teamMap from "../components/team/teamMap.json"
import { DefaultBottom } from "../components/banner/DefaultBottom"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
      }
    }
    allContentfulMember: {
      nodes: Array<MemberInfo>
    }
  }
}

const Member = ({ member }: { member: MemberInfo }) => {
  return (
    <div className="column is-one-third">
      <Link
        to={`/team/${member.slug}`}
        className="team-avatar is-pulled-left mr-4"
      >
        <img src={member.gravatar} alt={member.title} />
      </Link>
      <div className="team-content">
        <div className="is-size-6 has-text-weight-bold">
          <Link to={`/team/${member.slug}`}>{member.title}</Link>
        </div>
        <div className="my-2 job">{member.job}</div>
        <div className="my-2">{member.country}</div>
      </div>
      {(member.github || member.twitter) && (
        <div className="team-social">
          {member?.github && (
            <a
              href={`https://github.com/${member.github}`}
              title={member.title}
              className=""
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          )}
          {member.twitter && (
            <a
              href={`https://twitter.com/${member.twitter}`}
              title={member.title}
              className=""
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          )}
        </div>
      )}
    </div>
  )
}

// markup
const TeamTemplate = (props: Props) => {
  const team = props.data.allContentfulMember.nodes
  const defaultProps = {
    center: {
      lat: 40,
      lng: -15,
    },
    zoom: 3,
  }
  const renderMarkers = (): React.ReactNode => {
    return team.map(member => {
      if (member?.location?.lat && member?.location?.lon) {
        const marker = (
          <Marker
            key={member.id}
            icon={getIcon(20, 32, 10, 32)}
            position={{
              lat: Number(member?.location?.lat),
              lng: Number(member?.location?.lon),
            }}
          />
        )
        return marker
      }
    })
  }

  const Map = (): JSX.Element => {
    const map = useMap()
    return (
      <map>
        {renderMarkers()}
        <GeoJSON data={teamMap.features} style={countryStyles} />
      </map>
    )
  }

  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className="team"
    >
      <div className="container mt-5 mb-2">
        <HTMLContent
          content={props.data.page.html}
          className={"content"}
        ></HTMLContent>
      </div>
      {typeof window !== "undefined" && (
        <div className="map-wrapper">
          <MapContainer
            center={[
              Number(defaultProps.center.lat),
              Number(defaultProps.center.lng),
            ]}
            zoom={defaultProps.zoom}
            scrollWheelZoom={false}
          >
            <Map />
          </MapContainer>
        </div>
      )}
      <div className="container my-6">
        <div className="list columns is-multiline">
          {team &&
            team.map(member => <Member key={member.id} member={member} />)}
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default TeamTemplate

// graphQL queries
export const pageQuery = graphql`
  query TeamTemplate($id: String!) {
    allContentfulMember(
      sort: { order: ASC, fields: [title] }
      filter: { inactive: { eq: false } }
    ) {
      nodes {
        id
        title
        twitter
        slug
        job
        gravatar
        githubId
        github
        country
        childContentfulMemberBioTextNode {
          bio
        }
        location {
          lat
          lon
        }
        inactive
      }
    }
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
      }
    }
  }
`
